import React from "react";
import {Commodity} from "../tapi";

interface Props {
    commodity: Commodity
}

function CommodityOption(props: Props) {
    return (
        <>
            {props.commodity.name}<br/>
            DPH: {props.commodity.vat}%<br/>
            Jednotka mnozstva: {props.commodity.unit}<br/>
            Transport: {props.commodity.transportRequirements}
        </>
    );
}

export default CommodityOption;